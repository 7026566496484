import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Price } from "@commercelayer/js-sdk";

const usePrice = ({ skuCode, userId }) => {
    return useQuery({
        queryKey: [`price`, skuCode, userId],
        queryFn: () => getPrice(skuCode, userId),
        enabled: !!skuCode && !!userId,
    });
};

const getPrice = (skuCode, userId) => {
    return Price.findBy({ sku_code: skuCode, reference: userId });
};

usePrice.propTypes = {
    skuCode: PropTypes.string.isRequired,
    reference: PropTypes.number.isRequired,
};
getPrice.propTypes = {
    skuCode: PropTypes.string.isRequired,
    reference: PropTypes.number.isRequired,
};

export { usePrice };
