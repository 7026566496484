import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useUser } from "./hooks";
import { FlagIcon } from "../../components/FlagIcon";

export const UserWidget = ({ className, userId, variant = `medium` }) => {
    const { isLoading, isError, data: user, error } = useUser(userId);

    switch (variant) {
        case `small`:
            return (
                <UserWidgetSmall
                    className={className}
                    error={error}
                    isError={isError}
                    isLoading={isLoading}
                    user={user && user.data}
                />
            );
        case `medium`:
            return (
                <UserWidgetMedium
                    className={className}
                    error={error}
                    isError={isError}
                    isLoading={isLoading}
                    user={user && user.data}
                />
            );
        default:
            return null;
    }
};

const UserWidgetSmall = ({ className, error, isError, isLoading, user }) => {
    if (isError) {
        return (
            <Typography style={{ color: `red` }}>{error.toString()}</Typography>
        );
    }
    if (isLoading || !user) {
        return <Skeleton animation="pulse" variant="text" width="100%" />;
    }

    return (
        <Link className={`leading-none ${className}`} to={`/user/${user.id}/`}>
            <FlagIcon
                className="mr-2"
                code={user.country}
                key={user.country}
                type="country"
            />
            &nbsp;{user.screen_name}
            <br />
        </Link>
    );
};

const UserWidgetMedium = ({ className, error, isError, isLoading, user }) => {
    if (isError) {
        return (
            <Typography style={{ color: `red` }}>
                Failed loading user.data: {error.toString()}
            </Typography>
        );
    }
    if (isLoading) {
        return <Skeleton animation="pulse" height={100} variant="rect" />;
    }
    const fullName = user.first_name && user.first_name + ` ` + user.last_name;

    return (
        <Grid className={`p-4 ${className}`} container>
            <Grid className="flex flex-col" item>
                <Grid container spacing={2}>
                    <Grid item>
                        <Avatar className="">{user.screen_name[0]}</Avatar>
                    </Grid>
                    <Grid item xs>
                        <FlagIcon
                            className="mr-2"
                            code={user.country}
                            key={user.country}
                            type="country"
                        />
                        <br />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

UserWidget.propTypes = {
    className: PropTypes.string,
    userId: PropTypes.number.isRequired,
    variant: PropTypes.oneOf([`small`, `medium`]),
};
