import * as React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import { usePrice } from "./hooks";
import { LocalPrice } from "../../common/LocalPrice";

export const Price = ({ className, skuCode, userId }) => {
    const { isLoading, data: price } = usePrice({ skuCode, userId });
    const priceCents =
        price && price.data && price.data.attributes.amount_cents;
    const priceCurrencyCode =
        price && price.data && price.data.attributes.currency_code;

    if (isLoading || !priceCents || !priceCurrencyCode) {
        return <Skeleton animation="pulse" variant="text" />;
    }

    return (
        <LocalPrice
            className={className}
            fromCurrencyCode={priceCurrencyCode}
            fromPrice={priceCents}
        />
    );
};

Price.propTypes = {
    className: PropTypes.string,
    skuCode: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
};
